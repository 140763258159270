'use-client';
import * as React from 'react';
import WPhoto from '../../WPhotoBase';
import type { SkinWPhotoProps } from '../SkinWPhoto';
import type { BaseWPhotoSkinProps } from '../../../WPhoto.types';
import skinsStyles from './styles/DoubleBorderPhoto.scss';
import BasicWPhotoSkin from './BasicWPhotoSkin';

const DoubleBorderPhotoSkin: React.FC<
  Omit<BaseWPhotoSkinProps, 'skinsStyle'>
> = props => <BasicWPhotoSkin {...props} skinsStyle={skinsStyles} />;

const DoubleBorderPhoto: React.FC<SkinWPhotoProps> = props => (
  <WPhoto {...props} skin={DoubleBorderPhotoSkin} />
);

export default DoubleBorderPhoto;
